import React from 'react'

const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer_child'>
                <p className='bvend'>@2023 Bvend</p>
            </div>

            <div className='footer_child'>
                <p>Privacy policy</p>
                <p>Terms and condition</p>
            </div>
        </div>
    )
}

export default Footer