import React from 'react'

const ApplyNewStudent = ({ setModal }) => {
    return (
        <div className='applyNewStudent'>
            <div>
                <p className='head'>Apply for new Student card</p>
                <p className='desc'>It’s Easy and fast</p>
                <button onClick={() => setModal("addStudent")}>Apply Now</button>
            </div>
        </div>
    )
}

export default ApplyNewStudent