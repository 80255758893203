import React, { useRef, useEffect, useState } from 'react'
import ClickoutSide from '../ClickoutSide/clickoutsite';
import instance from '../../Instance/instance';
import CryptoJS from 'crypto-js';
import { toast } from 'react-toastify';
import warn1 from '../../assets/warn1.png'

const Payment = ({ setPaymentDetail, objectInfo, setModal, handleBackPayment, setLoader }) =>
{
    const ref1 = useRef();
    const ref2 = useRef();

    const [payNo, setPayNo] = useState("")
    const [countdown, setCountdown] = useState(5);
    const [showProcess, setShowProcess] = useState(1)
    const [dis, setDis] = useState(false)
    const userId = localStorage.getItem("userId")

    const encryptAndSaveToLocalStorage = (key, value) =>
    {
        const encryptedValue = CryptoJS.AES.encrypt(value, 'lis123$').toString();
        localStorage.setItem(key, encryptedValue);
    };


    const countdownRef = useRef(countdown);

    const handlePayStep1 = () =>
    {
        if (!payNo)
        {
            return toast.error("Account No required");
        }
        setShowProcess(2);

    }
    console.log("objectInfo", objectInfo)
    const handlePayment = async () =>
    {
        setDis(true);
        setPaymentDetail(objectInfo);
        localStorage.setItem("userId", objectInfo?.id);
        encryptAndSaveToLocalStorage("amount", JSON.stringify(objectInfo?.amount));
        executeApiCall();
        // const countdownInterval = setInterval(() =>
        // {
        //     countdownRef.current = countdownRef.current - 1;

        //     setCountdown(countdownRef.current);

        //     if (countdownRef.current <= 0)
        //     {
        //         clearInterval(countdownInterval);
        //         executeApiCall();
        //     }
        // }, 1000);
    }

    const executeApiCall = async () =>
    {
        // alert("NOW")
        const body = {
            userId: objectInfo?.id,
            paymentId: "",
            paymentStatus: 0,
            amount: parseInt(objectInfo?.amount)
        }
        try
        {
            const topupRes = await instance.post("TopUpPayment", body)
            const res = await instance.get(`/BkashTopup?payerReference=${ payNo }&amount=${ objectInfo?.amount }&type=1&transactionId=${ topupRes?.data?.paymentId }`);
            window.open(res?.data?.bkashURL, "_self");
            setDis(false);
            // setShowProcess(2);
        } catch (error)
        {
            setDis(false);
        }
    };

    useEffect(() =>
    {
        ClickoutSide(ref1, ref2, setModal, "")
    }, [])

    useEffect(() =>
    {
        setPaymentDetail(objectInfo)
    }, [objectInfo])


    return (
        <div ref={ref1} className='payment'>
            {showProcess === 1 ?
                <div ref={ref2} className='payment_inner shadow'>
                    <div className='payment_topHead'>
                        <p className='head'>Payment</p>
                        <i onClick={() => setModal("")} class="fa-solid cross fa-xmark"></i>
                        <i onClick={() => handleBackPayment()} class="fa-solid backBtn fa-chevron-left"></i>
                    </div>
                    <div className='payment_userDetail'>
                        <div className='payment_child'>
                            <p className='name'>{objectInfo && objectInfo?.username}</p>
                            <p className='desc'>{objectInfo && objectInfo?.schoolName}</p>
                        </div>
                        <div className='payment_child'>
                            <p className='availableBal'>Available balance</p>
                            <p className='amount'>{objectInfo && objectInfo?.balance}Tk</p>
                        </div>
                    </div>
                    <div className='payment_inputBox'>
                        <label>Account No</label>
                        <input value={payNo} onChange={(e) => setPayNo(e.target.value)} placeholder='Enter Account No' />
                    </div>
                    <div className='payment_confirm'>
                        <div className='payment_confirmChild'>
                            <p className='amount'>{objectInfo?.amount}Tk</p>
                            <p className='amountText'>Topup Amount</p>
                        </div>
                        <div className='payment_confirmChild'>
                            <button onClick={handlePayStep1}>Confirm to pay</button>
                        </div>
                    </div>
                </div> :
                <div ref={ref2} className='payment_inner shadow'>
                    <img className='payment_warn' src={warn1} alt='' />
                    <div className='payment_warnText'>
                        <p>In order to avoid having your payment not completed, please do not close the browser until you have returned to this page.</p>
                        {/* {countdown !== 0 && <p>Redirecting in <span>{countdown}</span> seconds</p>} */}
                        <div className='payment_confirmChild'>
                            <button disabled={dis} onClick={handlePayment}>Proceed</button>
                        </div>
                    </div>
                </div>}
        </div>
    )
}

export default Payment