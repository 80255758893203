import CryptoJS from 'crypto-js';

export const decryptLocalstorage = (key) =>
{
    const encryptedValue = localStorage.getItem(key);

    if (encryptedValue)
    {
        try
        {
            const bytes = CryptoJS.AES.decrypt(encryptedValue, 'lis123$');
            const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);

            if (decryptedValue)
            {
                return decryptedValue;
            } else
            {
                console.log('Decryption failed. Data may not be valid UTF-8.');
            }
        } catch (error)
        {
            console.error('Error decrypting data:', error);
        }
    }

    return null;
};