import React, { useState, useRef, useEffect } from 'react'
import tickIcon from "../../assets/Bvend-Student-Assets-19.png";
import ClickoutSide from '../ClickoutSide/clickoutsite';


const PaymentSuccesfull = ({ clearEmail, paymentDetail, setModal, status, setStatus, handleReceiptClose }) => {
    const [email, setEmail] = useState("")
    const [msg, setMsg] = useState("")
    const ref1 = useRef();
    const ref2 = useRef();
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    const handleEmail = () => {
        if (!email) {
            setMsg("Email Required")
        } else if (!email?.match(regexEmail)) {
            setMsg("Please enter valid Email")
        }
        else {
            setStatus(1)
            clearEmail()
        }
    }

    const done = (e) => {
        if (e?.key === "Enter") {
            handleEmail()
        }
    }

    useEffect(() => {
        if (paymentDetail?.email) {
            setStatus(1)
            setEmail(paymentDetail?.email)
            clearEmail()
        } else {
            setStatus(-1)
        }
    }, [])

    useEffect(() => {
        ClickoutSide(ref1, ref2, setModal, "")
    }, [])

    return (
        <div ref={ref1} className='paymentSuccesfull'>
            <div ref={ref2} className='paymentSuccesfull_inner shadow'>
                {status === -1 &&
                    <>
                        <div className='paymentSuccesfull_topHead'>
                            <p className='head'>Payment Successful</p>
                            <i onClick={() => setModal("")} class="fa-solid cross fa-xmark"></i>
                        </div>

                        <div className='paymentSuccesfull_tickInfo'>
                            <img className='tickIcon' src={tickIcon} alt="tickIcon" />
                            <p className='amount'>100Tk</p>
                            <p className='lable'>Topup Amount</p>
                            <p className='msg'>Enter email address if you want to receive the receipt</p>
                        </div>


                        <div className='paymentSuccesfull_send'>
                            <input onKeyDown={(e) => done(e)} onChange={(e) => setEmail(e?.target.value)} value={email} type="text" placeholder='Enter email address' />
                            <p className='paymentSuccesfull_msg'>{msg && `* ${msg} *`}</p>
                            <button onClick={() => handleEmail()}>Send</button>
                        </div>
                    </>
                }

                {
                    status === 1 &&
                    <>
                        <div className='paymentSuccesfull_topHead'>
                            <p className='head'>Receipt Sent</p>
                            <i onClick={() => handleReceiptClose()} class="fa-solid cross fa-xmark"></i>
                        </div>

                        <div className='paymentSuccesfull_tickInfo'>
                            <img className='tickIcon' src={tickIcon} alt="tickIcon" />
                            <p style={{ textAlign: "center" }} className='lable'>Receipt has been sent successfully</p>
                            <p style={{ textAlign: "center", margin: "25px 0px" }} className='msg'> {email && email} </p>
                        </div>
                    </>
                }

            </div>
        </div>
    )
}

export default PaymentSuccesfull