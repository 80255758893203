import React from 'react'

const MiniLoader = () => {
    return (
        <div className='miniLoader'>
            <span class="miniLoader_loader"></span>
        </div>
    )
}

export default MiniLoader