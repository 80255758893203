import React, { useEffect, useRef } from 'react'
import StudentList from '../StudentList'
import studentFakeData from '../../fakeData/studentFakeData'
import homeIcon from "../../assets/bve-home-01.png";
import ClickoutSide from '../ClickoutSide/clickoutsite';
import NoDataFound from '../NoDataFound';

const StudentDetails = ({ setObjectInfo, setModal, email, studentList }) =>
{
    const ref1 = useRef();
    const ref2 = useRef();

    useEffect(() =>
    {
        ClickoutSide(ref1, ref2, setModal, "")
    }, [])



    return (
        <div ref={ref1} className='studentDetails'>
            <div ref={ref2} className='studentDetails_inner shadow'>
                <div className='studentDetails_topHead'>
                    <p className='head'>Students Detail</p>
                    <p className='email'> {email ? email : "johnbrad@gmail.com"} </p>
                    <button onClick={() => setModal("")} className='notMe'> <img className='homeIcon' src={homeIcon} alt="homeIcon" /> Not me</button>
                    <i onClick={() => setModal("")} class="fa-solid cross fa-xmark"></i>
                </div>

                {studentList?.length > 0 &&
                    <div className='studentDetails_scroller'>
                        {studentList?.map(item =>
                        {
                            return (
                                <StudentList setObjectInfo={setObjectInfo} setModal={setModal} item={item} />
                            )
                        })}
                    </div>}
                {studentList?.length === 0 && <NoDataFound msg={"No student found with"} email={email} />}
            </div>
        </div>
    )
}

export default StudentDetails