import React from 'react'
import { Suspense } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import Home from '../pages/Home';
// import AdminPrivate from './AdminPrivate';

const MyRoutes = () => {

    // const isLogged = localStorage?.getItem("isLogged");

    return (
        <BrowserRouter>
            <Suspense fallback={<h2>Loading...</h2>}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="*" element={<Navigate to="/" replace />}></Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}

export default MyRoutes