import React, { useState, useRef, useEffect } from 'react'
import tickIcon from "../../assets/Bvend-Student-Assets-19.png";
import crossIcon from "../../assets/failed.png";
import ClickoutSide from '../ClickoutSide/clickoutsite';
import { useNavigate } from 'react-router-dom';
import MiniLoader from '../MiniLoader';


const WaitMessage = () =>
{
    // const [email, setEmail] = useState("")
    const ref1 = useRef();
    const ref2 = useRef();
    const navigate = useNavigate()

    // useEffect(() =>
    // {
    //     if (paymentDetail?.email)
    //     {
    //         setStatus(1)
    //         setEmail(paymentDetail?.email)
    //         clearEmail()
    //     } else
    //     {
    //         setStatus(-1)
    //     }
    // }, [])

    useEffect(() =>
    {
        localStorage.clear()
    }, [])

    return (
        <div ref={ref1} className='paymentSuccesfull'>
            <div ref={ref2} className='paymentSuccesfull_inner shadow'>
                <div className='paymentSuccesfull_topHead'>
                    <p className='head'>Payment Status</p>
                </div>
                <div className='paymentSuccesfull_tickInfo'>
                    <MiniLoader />
                    <p style={{ textAlign: "center", margin: "25px 0px" }} className='lable'>Payment is being processed. Don't refresh or close the window.</p>
                </div>
            </div>
        </div>
    )
}

export default WaitMessage