import axios from "axios";




const instance = axios.create({
    // deveploment
    // baseURL: "https://bvendapidev.azurewebsites.net/api/",
    // production
    baseURL: "https://bvendapis0303.azurewebsites.net/api/",
    headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
    },
});

export default instance;
