import React, { useRef, useEffect, useState } from 'react'
import ClickoutSide from '../ClickoutSide/clickoutsite';
import instance from '../../Instance/instance';
import { toast } from 'react-toastify';
import axios from 'axios';
import warn1 from '../../assets/warn1.png'
import { decryptLocalstorage } from '../../utils/decryptLocalstorage';


const Payment2 = ({ requestId, paymentDetail, setModal, handleBackPayment2, setLoader }) =>
{
    const cardNumberRef = useRef(null)
    const ref1 = useRef();
    const ref2 = useRef();
    const [payNo, setPayNo] = useState("")
    const [dis, setDis] = useState(false)
    const [countdown, setCountdown] = useState(5);
    const [showProcess, setShowProcess] = useState(1)
    const countdownRef = useRef(countdown);
    const  reqId = decryptLocalstorage("requestId")
    const handlePayStep1 = () =>
    {
        if (!payNo)
        {
            return toast.error("Account No required");
        }
        setShowProcess(2);

    }
    const handlePayment = async () =>
    {
        if (!payNo)
        {
            toast.error("Account no required")
        } else
        {
            setShowProcess(2)
            setDis(true)
            executeApiCall()
            // const countdownInterval = setInterval(() =>
            // {
            //     countdownRef.current = countdownRef.current - 1;

            //     setCountdown(countdownRef.current);

            //     if (countdownRef.current <= 0)
            //     {
            //         clearInterval(countdownInterval);
            //         executeApiCall();
            //     }
            // }, 1000);

        }
        // handleCreatePayment()
    }

    const executeApiCall = async () =>
    {
        const body = {
            requestId: parseInt(reqId),
            paymentId: "",
            paymentStatus: 0,
            amount: 160
        }
        try
        {
            const paymentRes = await instance.post("CardPayment", body)
            console.log("==>", paymentRes)
            const res = await instance.get(`/Bkash?payerReference=${ payNo }&amount=160&type=0&transactionId${paymentRes?.data?.requestId}`)
            window.open(res?.data?.bkashURL, "_self")
            setDis(false)

        } catch (error)
        {
            setDis(false)
        }
    };
    useEffect(() =>
    {
        ClickoutSide(ref1, ref2, setModal, "")
    }, [])

    return (
        <div ref={ref1} className='payment'>
            {showProcess === 1 ?
                <div ref={ref2} className='payment_inner shadow'>
                    <div className='payment_topHead'>
                        <p className='head'>Payment</p>
                        <i onClick={() => setModal("")} class="fa-solid cross fa-xmark"></i>
                        <i onClick={() => handleBackPayment2()} class="fa-solid backBtn fa-chevron-left"></i>
                    </div>
                    <div className='payment_userDetail'>
                        <div className='payment_child'>
                            <p className='name'>{paymentDetail?.name}</p>
                            <p className='desc'>{paymentDetail?.schoolName}</p>
                        </div>
                        <div className='payment_child'>
                        </div>
                    </div>
                    <br />
                    <div className='payment_inputBox'>
                        <label>Enter Account Number</label>
                        <input name='payerReference' value={payNo} onChange={(e) => setPayNo(e.target.value)} ref={cardNumberRef} type="text" placeholder='Enter Account Number' />
                    </div>
                    <br />
                    <div className='payment_cardAmounts'>
                        <div className='payment_cardAmountChild'>
                            <p className='lable'>Card Price</p>
                            <p className='lable'>Credit Amount</p>
                            <p className='lable'>Delivery Fee</p>
                        </div>
                        <div className='payment_cardAmountChild'>
                            <p style={{ textAlign: "right" }}>30Tk</p>
                            <p style={{ textAlign: "right" }}>70Tk</p>
                            <p style={{ textAlign: "right" }}>60Tk</p>
                        </div>
                    </div>


                    <div className='payment_confirm'>
                        <div className='payment_confirmChild'>
                            <p className='amount'>160Tk</p>
                            <p className='amountText'>Total Amount</p>
                        </div>
                        <div className='payment_confirmChild'>
                            <button onClick={handlePayStep1}>Confirm to pay</button>
                        </div>
                    </div>
                </div> :
                <div ref={ref2} className='payment_inner shadow'>
                    <img className='payment_warn' src={warn1} alt='' />
                    <div className='payment_warnText'>
                        <p>In order to avoid having your payment not completed, please do not close the browser until you have returned to this page.</p>
                        <div className='payment_confirmChild'>
                            <button disabled={dis} onClick={handlePayment}>Proceed</button>
                        </div>
                        {/* {countdown !== 0 && <p>Redirecting in <span>{countdown}</span> seconds</p>} */}
                    </div>
                </div>}
        </div>
    )
}

export default Payment2