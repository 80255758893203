import React, { useState, useEffect, useRef } from 'react'
import ClickoutSide from '../ClickoutSide/clickoutsite';
import { toast } from "react-toastify";

// Yellow
import yellowIncrease from "../../assets/Bvend-Student-Assets-08.png";
import yellowDecrease from "../../assets/Bvend-Student-Assets-09.png";

// Grey 
import yellowIncreaseGrey from "../../assets/Bvend-Student-Assets-07.png";
import yellowDecreaseGrey from "../../assets/Bvend-Student-Assets-06.png";

const TopUp = ({ setObjectInfo, setPaymentDetail, objectInfo, setModal, setLoader, paymentDetail }) => {
    const ref1 = useRef();
    const ref2 = useRef();
    const [amount, setAmount] = useState(100)
    console.log("objectInfo", objectInfo)

    const handleBack = () => {
        setModal("studentDetail")
    }


    const handleValue = (res) => {
        if (res === "decrease") {
            if (amount > 100) {
                setAmount(prev => prev - 100)
            }
        } else if (res === "increase") {
            if (amount < 1000) {
                setAmount(prev => prev + 100)
            }
        }
    }


    const handlePayment = async () => {
        // const body = {
        //     "paymentId": 3,
        //     "userId": objectInfo?.id,
        //     "paymentStatus": 1,
        //     "amount": amount
        // }
        try {
            if (amount === 0) {
                toast.warning("Please add value")
            } else {
                setModal("paymentDetail1")
                setObjectInfo({ ...objectInfo, amount })
            }
        } catch (err) {
            setLoader(false)
            setModal("")
        }
    }


    useEffect(() => {
        ClickoutSide(ref1, ref2, setModal, "")
    }, [])


    useEffect(() => {
        if (objectInfo?.amount) {
            setAmount(objectInfo?.amount)
        } else {
            setAmount(100)
        }
    }, [])


    return (
        <div ref={ref1} className='topUp'>
            <div ref={ref2} className="topUp_inner shadow">
                <div className='topUp_topHead'>
                    <p className='head'>Select Topup Value</p>
                    <i onClick={() => setModal("")} class="fa-solid cross fa-xmark"></i>
                    <i onClick={() => handleBack()} class="fa-solid backBtn fa-chevron-left"></i>
                </div>

                <div className='topUp_userDetail'>
                    <div className='topUp_child'>
                        <p className='name'>{objectInfo && objectInfo?.username}</p>
                        <p className='desc'>{objectInfo && objectInfo?.schoolName}</p>
                    </div>
                    <div className='topUp_child'>
                        <p className='availableBal'>Available balance</p>
                        <p className='amount'>{objectInfo && objectInfo?.balance}Tk</p>
                    </div>
                </div>

                <div className='topUp_amountBox'>
                    <div className='topUp_amountBoxChild'>
                        <img onClick={() => handleValue("decrease")} src={amount === 100 ? yellowDecreaseGrey : yellowDecrease} alt="yellowDecrease" />
                    </div>
                    <div className='topUp_amountBoxChild'>
                        <p>{amount}Tk</p>
                    </div>
                    <div className='topUp_amountBoxChild'>
                        <img onClick={() => handleValue("increase")} src={amount >= 1000 ? yellowIncreaseGrey : yellowIncrease} alt="yellowIncrease" />
                    </div>
                </div>

                <div className='topUp_description'>
                    <p>*Topup value starts from <b>100Tk</b> upto <b>1000Tk</b>*</p>
                </div>


                <div className='topUp_paymentBtn'>
                    <button onClick={() => handlePayment()}>Payment</button>
                </div>

            </div>
        </div>
    )
}

export default TopUp