

import React, { useRef, useEffect, useState } from 'react'
import ClickoutSide from '../ClickoutSide/clickoutsite';
import instance from '../../Instance/instance'
import { toast } from "react-toastify";
import { City, State } from 'country-state-city';
import CryptoJS from 'crypto-js';

const AddStudent = ({ setPaymentDetail, setRequestId, setModal, setLoader }) =>
{
    const cardNumberRef = useRef(null)
    const expiryRef = useRef(null)
    const cvvRef = useRef(null)
    const ref1 = useRef();
    const ref2 = useRef();
    const [inputValues, setInputValues] = useState({})
    const [city, setCity] = useState([]);
    const [selectedState, setSelectedState] = useState("")
    const [schoolList, setSchoolList] = useState([])
    const [schoolName, setSchoolName] = useState("")
    const [schoolLoader, setSchoolLoader] = useState(false);
    const [state, setState] = useState([
        {
            "countryCode": "BD",
            "isoCode": "06",
            "latitude": "22.70220980",
            "longitude": "90.36963160",
            "name": "Barisal District"
        },
        {
            "countryCode": "BD",
            "isoCode": "B",
            "latitude": "23.17931570",
            "longitude": "91.98815270",
            "name": "Chittagong Division"
        },
        {
            "countryCode": "BD",
            "isoCode": "13",
            "latitude": "23.81051400",
            "longitude": "90.33718890",
            "name": "Dhaka District"
        },
        {
            "countryCode": "BD",
            "isoCode": "27",
            "latitude": "22.67377350",
            "longitude": "89.39665810",
            "name": "Khulna District"
        },
        {
            "countryCode": "BD",
            "isoCode": "34",
            "latitude": "24.75385750",
            "longitude": "90.40729190",
            "name": "Mymensingh District"
        },
        {
            "countryCode": "BD",
            "isoCode": "54",
            "latitude": "24.37330870",
            "longitude": "88.60487160",
            "name": "Rajshahi District"
        },
        {
            "countryCode": "BD",
            "isoCode": "55",
            "latitude": "25.74679250",
            "longitude": "89.25083350",
            "name": "Rangpur District"
        },
        {
            "countryCode": "BD",
            "isoCode": "60",
            "latitude": "24.89933570",
            "longitude": "91.87004730",
            "name": "Sylhet District"
        }
    ]);
    const regex = /^\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*$/?.test(inputValues?.email)


    const handleValueChange = (e) =>
    {
        setInputValues({ ...inputValues, [e?.target?.name]: e?.target?.value })
    }

    const encryptAndSaveToLocalStorage = (key, value) =>
    {
        const encryptedValue = CryptoJS.AES.encrypt(value, 'lis123$').toString();
        localStorage.setItem(key, encryptedValue);
    };

    const handleAddStudent = async () =>
    {
        const body = {
            "schoolId": parseInt(inputValues?.schoolId),
            "name": inputValues?.name,
            "email": inputValues?.email,
            "phoneNumber": inputValues?.phoneNumber,
            "address1": inputValues?.address1,
            "address2": inputValues?.address2,
            "state": selectedState,
            "city": inputValues?.city,
            "postalCode": inputValues?.postalCode,
            "createdAt": new Date(),
        }
        try
        {
            if (!inputValues?.schoolId)
            {
                toast.error("School Required")
            } else if (!inputValues?.name)
            {
                toast.error("Student Name Required")
            }
            else if (!inputValues?.phoneNumber)
            {
                toast.error("Phone Number Required")
            } else if (!inputValues?.address1)
            {
                toast.error("Address 1 Required")
            } else if (!selectedState)
            {
                toast.error("State Required")
            } else if (!inputValues?.city)
            {
                toast.error("City Required")
            } else if (!inputValues?.postalCode)
            {
                toast.error("Postal Code Required")
            } else
            {
                if (inputValues?.email?.length > 0 && !regex)
                {
                    toast.warning("Email Valid Required")
                } else
                {
                    setModal("")
                    setLoader(true)
                    const res = await instance.post("/CardRequest", body)
                    if (res?.data?.status === "success")
                    {
                        setRequestId(res?.data?.requestId)
                        encryptAndSaveToLocalStorage("requestId", JSON.stringify(res?.data?.requestId))
                        encryptAndSaveToLocalStorage("amount", JSON.stringify(160))
                        // localStorage.setItem("requestId", res?.data?.requestId)
                        setPaymentDetail({ ...inputValues, schoolName })
                        setModal("paymentDetail2")
                        setLoader(false)
                    }
                }
            }

        } catch (err)
        {
            setModal("")
            setLoader(false)
            toast.error(err?.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }




    const Welcome1 = (e) =>
    {
        var test = e.target.value.split(',');
        console.log(test);
        setCity(City.getCitiesOfState(test[0], test[1]))
    }



    const gettingSchoolList = async () =>
    {
        setSchoolLoader(true);
        const res = await instance.get("School")
        setSchoolList(res?.data)
        setSchoolLoader(false);
    }



    useEffect(() =>
    {
        const ignoreScroll = (e) =>
        {
            e.preventDefault();
        };
        cardNumberRef.current && cardNumberRef.current.addEventListener("wheel", ignoreScroll);
        expiryRef.current && expiryRef.current.addEventListener("wheel", ignoreScroll);
        cvvRef.current && cvvRef.current.addEventListener("wheel", ignoreScroll);
    }, [cardNumberRef, expiryRef, cvvRef]);


    useEffect(() =>
    {
        ClickoutSide(ref1, ref2, setModal, "")
    }, [])

    useEffect(() =>
    {
        gettingSchoolList()
    }, [])



    return (
        <div ref={ref1} className='addStudent'>
            <div ref={ref2} className='addStudent_inner shadow'>
                <div className='addStudent_topHead'>
                    <p className='head'>Add New Student Card</p>
                    <i onClick={() => setModal("")} class="fa-solid cross fa-xmark"></i>
                </div>

                <div className='addStudent_inputBox'>
                    <label>Select School</label>
                    <select value={inputValues?.schoolId} name='schoolId' onChange={(e) => { handleValueChange(e); setSchoolName(e.target.selectedOptions[0].getAttribute('data-set')) }} >
                        <option selected disabled>Select School Here</option>
                        {schoolLoader && <option>Loading...</option>}
                        {schoolList?.map((item) =>
                        {
                            return (
                                <>
                                    <option data-set={item?.name} value={item?.id}>{item?.name}</option>
                                </>
                            )
                        })}
                    </select>
                </div>

                <div className='addStudent_inputBox'>
                    <label>Student Name</label>
                    <input name='name' onChange={(e) => handleValueChange(e)} value={inputValues?.name} type="text" placeholder='Enter Student Name' />
                </div>


                <div className='addStudent_inputBox'>
                    <label>Email Address (Optional)</label>
                    <input name='email' onChange={(e) => handleValueChange(e)} value={inputValues?.email} type="text" placeholder='Email' />
                </div>

                <div className='addStudent_inputBox'>
                    <label>Phone Number</label>
                    <input name='phoneNumber' onChange={(e) => handleValueChange(e)} value={inputValues?.phoneNumber} ref={cvvRef} type="number" placeholder='Phone' />
                </div>


                <div className='addStudent_inputBox'>
                    <label>Address 1</label>
                    <input name='address1' onChange={(e) => handleValueChange(e)} value={inputValues?.address1} type="text" placeholder='Enter Address 1' />
                </div>

                <div className='addStudent_inputBox'>
                    <label>Address 2 (Optional)</label>
                    <input name='address2' onChange={(e) => handleValueChange(e)} value={inputValues?.address2} type="text" placeholder='Enter Address 2' />
                </div>


                <div className='addStudent_inputBox'>
                    <label>State</label>
                    <select onChange={(e) => { Welcome1(e); setSelectedState(e.target.selectedOptions[0].getAttribute('data-set')) }} name='state'  >
                        <option selected disabled>Select State</option>
                        {/* {state?.map((item) => {
                                return (
                                    <option value={item?.name}>{item?.name}</option>
                                )
                            })} */}
                        {
                            state.map(stat => <option data-set={stat.name} value={stat.countryCode + "," + stat.isoCode}>{stat.name}</option>)
                        }
                    </select>
                </div>

                <div className='addStudent_inputBox'>
                    <label>City</label>
                    <select disabled={selectedState ? false : true} name='city' onChange={(e) => handleValueChange(e)} >
                        <option defaultChecked selected disabled> {!selectedState ? "First Select State For City" : "Select City"} </option>
                        {
                            city?.length === 0 ? <option disabled value="">No City Found With This State</option> :
                                city.map(cit => <option value={cit?.name} >{cit.name}</option>)
                        }
                    </select>
                </div>

                <div className='addStudent_inputBox'>
                    <label>Enter Postal Code</label>
                    <input name='postalCode' value={inputValues?.postalCode} onChange={(e) => handleValueChange(e)} type="text" placeholder='Enter Postal Code' />
                </div>


                <div className='addStudent_cardAmounts'>
                    <div className='addStudent_cardAmountChild'>
                        <p className='lable'>Card Price</p>
                        <p className='lable'>Credit Amount</p>
                        <p className='lable'>Delivery Fee</p>
                    </div>
                    <div className='addStudent_cardAmountChild'>
                        <p style={{ textAlign: "right" }}>30Tk</p>
                        <p style={{ textAlign: "right" }}>70Tk</p>
                        <p style={{ textAlign: "right" }}>60Tk</p>
                    </div>
                </div>


                <div className='addStudent_confirm'>
                    <div className='addStudent_confirmChild'>
                        <p className='amount'>160Tk</p>
                        <p className='amountText'>Total Amount</p>
                    </div>
                    <div className='addStudent_confirmChild'>
                        <button onClick={() => handleAddStudent()}>Confirm to pay</button>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AddStudent