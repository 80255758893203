import React from 'react'

const StudentList = ({ setObjectInfo, item, setModal }) => {

    const handleOpen = () => {
        setModal("topUpDetail")
        setObjectInfo({ ...item, amount: 0 })
        console.log(item)
    }

    return (
        <div className='studentList'>
            <div className='studentList_top'>
                <p className='name'>{item?.username}</p>
            </div>
            <div className='studentList_info'>
                <div className='studentList_child'>
                    <p className='desc'>{item?.schoolName?.length > 40 ? `${item?.schoolName?.slice(0, 40)}...` : item?.schoolName}</p>
                    <p className='amount'>{item?.balance}TK <span className='lable'>Available balance  </span> </p>
                </div>
                <div className='studentList_child'>
                    <button onClick={() => handleOpen()} className='topUpBtn'>Topup</button>
                </div>
            </div>
        </div>
    )
}

export default StudentList