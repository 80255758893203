
import React, { useRef, useState, useEffect } from 'react'
import nextIcon from "../../assets/Bvend-Student-Assets-17.png"
import vendingMachine from "../../assets/Bvend-01.png"
import Navbar from '../Navbar'
import validator from "validator";
import instance from '../../Instance/instance';

const Banner = ({ setStudentList, setLoader, email, setEmail, setModal }) => {
    const [msg, setMsg] = useState("")
    const emailRef = useRef()

    const handleEmail = async () => {
        try {
            // const isPotentiallyEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
            const regex = /^\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*$/?.test(email)
            if (regex) {
                if (validator.isEmail(email)) {
                    setLoader(true)
                    const emailResponse = await instance.get(`/User?query=${email}`)
                    setStudentList(emailResponse?.data)
                    setLoader(false)
                    setModal("studentDetail")
                    setMsg("")
                } else {
                    setMsg("Email Valid Required")
                }
            } else {
                if (!/^\d+$/.test(email)) {
                    setMsg("Please enter a valid email, or if you want to use a number, enter only numbers");
                } else {
                    setLoader(true)
                    const numberResponse = await instance.get(`User?query=${email}`)
                    setStudentList(numberResponse?.data)
                    setLoader(false)
                    setModal("studentDetail")
                    setMsg("")
                }
            }
        } catch (err) {
            setLoader(false)
            setMsg(err?.code)
            console.log(err)
        }

    }


    const done = (e) => {
        if (e?.key === "Enter") {
            handleEmail()
        }
    }


    useEffect(() => {
        const withoutSpace = email.replace(/\s/g, '')
        setEmail(withoutSpace)
    }, [email])




    return (
        <div className='banner'>
            <Navbar />
            <div className='banner_child'>
                <div className='banner_leftImg'>
                    <p>Now you can <span className='toptup'>Topup</span>  <br /> and Check the available balance <br /> in the card from here</p>
                </div>

                <div className='banner_inputWrap'>
                    <input ref={emailRef} onKeyDown={(e) => done(e)} value={email} onChange={(e) => setEmail(e?.target?.value)} type="text" placeholder='Enter email or phone number' />
                    <img onClick={() => handleEmail()} src={nextIcon} className='nextIcon' alt="nextIcon" />
                </div>

                <p className='banner_msg'> {msg && `*${msg}*`} </p>

            </div>
            <div className='banner_child'>
                <div className='banner_rightImg'>
                    <img src={vendingMachine} className='banner_rightImg' alt="vendingMachine" />
                </div>
            </div>
        </div>
    )
}

export default Banner
