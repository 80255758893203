import React from 'react'
import noDataFoundImg from "../../assets/empty.png";

const NoDataFound = ({ msg, email }) => {
    return (
        <div className='noDataFound'>
            <div className='noDataFound_msg'>
                <p>{msg} <br/> <span style={{ color: "#ED7C37" }}>{email}</span> </p>
                {/* <img src={noDataFoundImg} alt="noDataFoundImg" /> */}
            </div>
        </div>
    )
}

export default NoDataFound