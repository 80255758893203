
import React, { useState, useEffect } from 'react'
import StudentDetails from '../../components/modals/StudentDetails'
import Banner from '../../components/Banner'
import ApplyNewStudent from '../../components/ApplyNewStudent'
import Footer from '../../components/Footer'
import TopUp from '../../components/modals/TopUp'
import Payment from '../../components/modals/Payment'
import PaymentSuccesfull from '../../components/modals/PaymentSuccesfull'
import AddStudent from '../../components/modals/AddStudent'
import Payment2 from '../../components/modals/Payment2'
import Loader from '../../components/Loader'
import BkashSuccess from '../../components/modals/BkashSuccess'
import instance from '../../Instance/instance'
import CryptoJS from 'crypto-js';
import WaitMessage from '../../components/modals/WaitMessage'

const Home = () =>
{
    const [status, setStatus] = useState(-1)
    const [loader, setLoader] = useState(false);
    const [wait, setWait] = useState(false);
    const [email, setEmail] = useState("")
    const [modal, setModal] = useState("")
    const [studentList, setStudentList] = useState([])
    const [objectInfo, setObjectInfo] = useState({})
    const [requestId, setRequestId] = useState("")
    const [paymentDetail, setPaymentDetail] = useState()

    const bkashStatus = new URLSearchParams(window.location.search)?.get("status")
    const paymentID = new URLSearchParams(window.location.search)?.get("paymentID")
    const paymentType = new URLSearchParams(window.location.search)?.get("type")
    // const bkashRequestId = localStorage.getItem('requestId')

    const handleBackPayment = () =>
    {
        setModal("topUpDetail")
    }

    const handleBackPayment2 = () =>
    {
        setModal("addStudent")
    }

    const handleReceiptClose = () =>
    {
        setModal("")
    }

    const clearEmail = () =>
    {
        setEmail("")
    }

    const decryptFromLocalStorage = (key) =>
    {
        const encryptedValue = localStorage.getItem(key);

        if (encryptedValue)
        {
            try
            {
                const bytes = CryptoJS.AES.decrypt(encryptedValue, 'lis123$');
                const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);

                if (decryptedValue)
                {
                    return decryptedValue;
                } else
                {
                    console.log('Decryption failed. Data may not be valid UTF-8.');
                }
            } catch (error)
            {
                console.error('Error decrypting data:', error);
            }
        }

        return null;
    };
    const reqId = decryptFromLocalStorage("requestId")
    const amnt = decryptFromLocalStorage("amount")
    const userId = localStorage.getItem("userId")


    const handleRequestPaymentStatus = async () =>
    {
        // setLoader(true)
        setWait(true)
        // const body = {
        //     requestId: parseInt(reqId),
        //     paymentId: paymentID,
        //     paymentStatus: bkashStatus === 'success' ? 1 : (bkashStatus === 'fail' || bkashStatus === 'failure') ? 2 : 3,
        //     amount: parseInt(amnt)
        // }
        try
        {
            // await instance.post("CardPayment", body)
            setLoader(false)
            setWait(false)
            setModal('bkashStatus')
        } catch (error)
        {
            setLoader(false)
            setWait(false)
            // setModal('bkashStatus')
        }
    }

    const handleTopupPaymentStatus = async () =>
    {
        // setLoader(true)
        setWait(true)
        // const body = {
        //     userId: parseInt(userId),
        //     paymentId: paymentID,
        //     paymentStatus: bkashStatus === 'success' ? 1 : (bkashStatus === 'fail' || bkashStatus === 'failure') ? 2 : 3,
        //     amount: parseInt(amnt)
        // }
        try
        {
            // const res = await instance.post("TopUpPayment", body)
            setLoader(false)
            setWait(false)
            setModal('bkashStatus')
        } catch (error)
        {
            setLoader(false)
            setWait(false)
        }
        // setModal('bkashStatus')
    }


    useEffect(() =>
    {
        if ((bkashStatus === 'success' || bkashStatus === 'fail' || bkashStatus === 'failure' || bkashStatus === 'cancel') && paymentType === "request")
        {
            handleRequestPaymentStatus()
        }
        if ((bkashStatus === 'success' || bkashStatus === 'fail' || bkashStatus === 'failure' || bkashStatus === 'cancel') && paymentType === "topup")
        {
            handleTopupPaymentStatus()
        }
    }, [])


    useEffect(() =>
    {
        if (modal)
        {
            document.body.style.overflow = "hidden"
        } else
        {
            document.body.style.overflow = "auto"
        }
    }, [modal])

    return (
        <div>
            {/* {loader && <Loader />} */}

            {/* <CountryComp /> */}
            {wait && <WaitMessage />}
            {modal === "studentDetail" && <StudentDetails setObjectInfo={setObjectInfo} studentList={studentList} setModal={setModal} email={email} />}
            {modal === "topUpDetail" && <TopUp paymentDetail={paymentDetail} setObjectInfo={setObjectInfo} setPaymentDetail={setPaymentDetail} objectInfo={objectInfo} setLoader={setLoader} setModal={setModal} />}
            {modal === "paymentDetail1" && <Payment setPaymentDetail={setPaymentDetail} objectInfo={objectInfo} paymentDetail={paymentDetail} setModal={setModal} handleBackPayment={handleBackPayment} setLoader={setLoader} />}
            {modal === "paymentDetail2" && <Payment2 paymentDetail={paymentDetail} requestId={requestId} setModal={setModal} handleBackPayment2={handleBackPayment2} setLoader={setLoader} />}
            {modal === "paymentSuccesfully" && <PaymentSuccesfull clearEmail={clearEmail} paymentDetail={paymentDetail} setModal={setModal} status={status} setStatus={setStatus} handleReceiptClose={handleReceiptClose} />}
            {modal === "addStudent" && <AddStudent setPaymentDetail={setPaymentDetail} setRequestId={setRequestId} setModal={setModal} setLoader={setLoader} />}
            {modal === "bkashStatus" && <BkashSuccess bkashStatus={bkashStatus} setModal={setModal} />}

            <Banner setStudentList={setStudentList} setModal={setModal} setLoader={setLoader} email={email} setEmail={setEmail} />
            <ApplyNewStudent setModal={setModal} />
            <Footer />
        </div>
    )
}

export default Home
